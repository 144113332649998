import logger from '../utils/logger';
import { TRACKER_LOCAL_STORAGE_KEYS } from '../shop/constants/tracker';

const getPurchaseLocalstorageKey = (orderId) =>
  `${TRACKER_LOCAL_STORAGE_KEYS.purchaseEventTriggered}_${orderId}`;

export const getPurchaseLocalstorageValue = (orderId) =>
  window?.localStorage?.getItem(getPurchaseLocalstorageKey(orderId));

export const setPurchaseLocalstorageValue = (orderId) =>
  window?.localStorage?.setItem(getPurchaseLocalstorageKey(orderId), true);

// it is a rough number
const TIMEOUT_TIME = 3000;
const getTimeout = () =>
  new Promise((_, reject) =>
    setTimeout(() => {
      reject(new Error('fail to get tracker service', { cause: 'timeout' }));
    }, TIMEOUT_TIME),
  );

const getNgTrackerService = () =>
  window.APP_EXTENSION_SDK_ANGULAR_JS_LOADED?.then(() =>
    window.angular.element(document.body).injector().get('trackerService'),
  );

const getTrackerService = () => {
  const trackerServicePromise = getNgTrackerService();
  const track = async (event, props) => {
    try {
      const service = await Promise.race([trackerServicePromise, getTimeout()]);
      service.track(event, props);
    } catch (error) {
      if (error.cause === 'timeout') {
        logger.error(error.cause, error.message);
      } else {
        logger.error('unknown', error);
      }
    }
  };
  return { track };
};

const trackerService = getTrackerService();

export default trackerService;
