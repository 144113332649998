app.config([
  '$translateProvider',
  function ($translateProvider) {
    $translateProvider.translations('zh-cn', {
      'languages.en': 'English',
      'languages.zh-hant': '中文',
      'languages.jp': '日本語',
      'languages.id': 'bahasa Indonesia',

      'form.action.delete': '删除',
      'paypal.language': 'zh_CN',
      close: '关闭',
      back: '返回',

      'form.validation.required': '{{field_name}} 是必须的',
      'form.validation.maxlength': '{{field_name}} 太长',
      'form.validation.maxlength.with.number':
        '{{field_name}}最多{{ max_length }}个字元',
      'form.validation.minlength': '{{field_name}} 太短',
      'form.validation.invalid': '{{field_name}} 无效',
      'form.validation.pattern': '{{field_name}} 无效',
      'form.validation.pattern.recipient-name':
        '不允许输入空白与符号，或超过 {{limit_length}} 个字',
      'form.validation.pattern.recipient-phone.TW':
        '{{field_name}} 限制输入10码数字',
      'form.validation.pattern.recipient-phone.fix_size':
        '收件人电话号码 限制輸入 {{ max_count }} 碼數字',
      'form.validation.pattern.recipient-phone.above_size':
        '收件人电话号码 限制输入 {{ max_count }} 码（含）以上数字',
      'form.validation.pattern.recipient-phone.beginning_stint':
        '收件人电话号码 限制开头须为 {{ stint_charts.split(",").join(" 或 ") }}',
      'form.validation.pattern.711_cross_border.delivery_address':
        '由于送货方式为跨境服务，必须以英文填写。',
      'form.validation.email': '{{field_name}} 无效',
      'form.validation.id_no': '提醒：若未填写身分证等实名资讯，可能影响报关',
      'form.validation.passport':
        '提醒：若未填写护照号码等实名资讯，可能影响报关',
      'form.validation.unique': '{{field_name}} 已被选用，请重新输入。',
      'form.validation.confirmation': '密码不一样',
      'form.validation.policy': '你必须同意细则和私隐条款',
      'form.validation.delivery_option.outlying':
        '您所选择的送货方式无法运送至离岛地区，请更换台湾本岛地址或选择其他送货方式。',
      'form.validation.delivery_option.local':
        '您所选择的送货方式无法运送至台湾本岛地区，请更换成离岛地址或选择其他送货方式。',
      'form.validation.delivery_option.country':
        '您所选择的送货方式无法运送至该国家/地区，请更换送货地址或选择其他送货方式。',
      'form.validation.delivery_option.region':
        '您所选择的送货方式无法运送至该地区，请更换地址或选择其他送货方式。',
      'form.validation.delivery_option.cross_border.MY':
        '您所选择的送货方式无法运送至{{ area == "west" ? "西" : "东" }}马，请更换成{{ area == "west" ? "东" : "西" }}马地址或选择其他送货方式。',
      'form.validation.birthday.required': '生日日期 是必须的',
      'form.validation.birthday.invalid': '生日日期 无效',
      'form.validation.birthday.invalid.minimum_age_limit':
        '生日日期无效，需大于 {{minimumAgeLimit}} 岁',
      'form.response.load.more.error':
        'Unable to load the data, please try again.',
      'form.validation.email.duplicate': '电邮 已被选用，请重新输入。',
      'form.validation.email.hint': '请输入正确的电邮地址',
      'form.validation.card.bin.error':
        '您所选的付款方式不适用于此信用卡，如有需要请联络店主。',
      'form.validation.card.bin.error.unknow_scheme':
        '我们无法判断你的信用卡类别，如有需要请联络店主。',
      'form.validation.calling_code': '区号 是必须的',
      'form.validation.phone.required': '{{field_name}} 是必须的',
      'form.validation.phone.error': '{{field_name}} 无效，请重新确认',
      'form.validation.mobile.error': '{{field_name}} 无效，请输入手机号码',
      'dropdown.hint': '请选择',
      'redirect.go_home_in_seconds': '{{seconds}}秒后回到首页',

      'trial_order_dialog.title':
        '欢迎体验结帐流程，全店最多能成立 10 张测试订单',
      'trial_order_dialog.content':
        '流程提供：加入会员、订阅优惠通知、购物车加购品等强大功能。达订单上限后，商家可以透过升级开通订单限制。',
      'trial_order_dialog.checkout': '开始结帐',
      'trial_order_dialog.reach_limit.title': '已达试营运下单限制',
      'trial_order_dialog.reach_limit.content':
        '待店家正式营运即可正式营运即可继续购物与结帐！<br/>若对商店营运有任何疑问，欢迎联络店家。',
      'trial_order_dialog.reach_limit.check_plan': '回首页继续逛逛',
      'trial_order_dialog.reach_limit.continue': '联络店家',

      'session.signup.title': '新用户注册',
      'session.terms.facebook': '使用 Facebook 注册',
      'session.signup.facebook': 'Facebook 登记',
      'sessions.or': '或',
      'session.terms': '登记／注册表示你同意我们的私隐政策，使用条款及细则。',
      'session.signin.submit': '登入',
      'session.signin.title': '登入',
      'session.signin.facebook': 'Facebook 登入',
      'session.forgot_password': '忘记密码',
      'session.mobile_signup.go_back': '上一页',
      'session.mobile_signup.check_mobile.title':
        '请确认您的手机号码是否输入正确，我们将传送简讯到以下手机号码',
      'session.mobile_signup.check_mobile.your_number': '您的手机号码',
      'session.mobile_signup.check_mobile.your_number.hint':
        '请输入台湾手机号码 (09开头)',
      'session.mobile_signup.check_mobile.send_code': '寄发验证码',
      'session.mobile_signup.check_mobile.go_back.1': '若手机号码有误，请回',
      'session.mobile_signup.check_mobile.go_back.2': '上一步',
      'session.mobile_signup.check_mobile.go_back.3': '修改',
      'session.mobile_signup.check_mobile.go_back.3.simplified': '修改',
      'session.mobile_signup.input_code.hint': '请输入收到的简讯验证码',
      'session.mobile_signup.input_code.hint.simplified':
        '请输入您收到的验证码',
      'session.mobile_signup.input_code.placeholder': '验证码',
      'session.mobile_signup.input_code.submit': '送出',
      'session.mobile_signup.input_code.resend.countdown':
        '重发验证码 ({{resendCountdown}}秒)',
      'session.mobile_signup.input_code.resend.countdown.simplified':
        '重新发送({{resendCountdown}}秒）',
      'session.mobile_signup.input_code.resend': '重发验证码',
      'session.mobile_signup.input_code.resend.simplified': '重新发送',
      'session.mobile_signup.recaptcha.load.failure':
        'Google reCAPTCHA 载入失败，请确认网路连线稳定后重新启动应用程式。',
      'session.mobile_signup.recaptcha.token.failure':
        '系统发生错误，请确认网路连线稳定后重新启动应用程式。',
      'session.forget_passowrd.check_phone.error': '手机号码无效，请重新确认',
      'session.mobile_center.check_phone.error': '此手机号码已注册过',
      'user.sign_up.email_verification.dialog.title': '验证信已寄至 {{mail}}',
      'user.sign_up.email_verification.dialog.description':
        '请立即完成收信验证，即可更新电邮！若没收到，请再次更改电邮并储存。',
      'user.sign_up.email_verification.dialog.confirm': '确定',
      'user.sign_up.check_phone.error':
        '此为现有手机号码，请重新填写需要更换的号码。',
      'user.delivery_data.recipient_phone': '收件人电话号码',

      'user.quick_signup.policy_with_age':
        '我同意网站<a href="{{ link }}">服务条款及隐私政策</a>，且我已满 {{ age }} 岁',

      'product.out_of_stock': '现在缺货',
      'product.addon_products.label': '加购品',
      'product.bundled_products.label': '任选优惠',
      'product.bundle_group_products.label': 'A+B 组合',
      'product.buyandget.label': '買 X 享 Y',
      'product.gift.label': '赠品',
      'product.subscription_product.label': '定期购',
      'product.addon_products.errors.main_product_out_of_stock':
        '主商品库存量不足',
      'product.addon_products.errors.reached_max_purchase_quantity':
        '商品购买上限为 {{ message }} 件',
      'product.addon_products.errors.addon_product_out_of_stock':
        '加购品库存量不足',
      'product.addon_products.errors.larger_addon_product':
        '购买的加购品数量不可大於主商品',
      'product.addon_products.errors.out_of_stock': '{{title}} 库存量不足',
      'product.addon_products.tips.limit_exceed':
        '调整商品数量时，你所选购的加购品数量不可大於主商品数量。',
      'product.addon_products.add_to_cart.hint': '已经成功加入到购物车',

      'product.available_time_info.start.1': '商品将于 ',
      'product.available_time_info.start.2': ' 开始贩售',
      'product.available_time_info.end.1': '商品将于 ',
      'product.available_time_info.end.2': ' 结束贩售',

      'product.amazon_product_review.show_more': '查看更多',

      'product_review_comments.orders.back_to_my_account': '< 返回个人中心',
      'product_review_comments.orders.comment': '內容',
      'product_review_comments.orders.comment_placeholder':
        '（选填）满意商品？跟我们分享你的评价！',
      'product_review_comments.orders.orders_to_view': '待评价订单',
      'product_review_comments.orders.order_id': '订单编号:',
      'product_review_comments.orders.purchased_date': '购买时间:',
      'product_review_comments.orders.rate_other_order': '评价其他订单',
      'product_review_comments.orders.review': '评价',
      'product_review_comments.orders.send': '提交',
      'product_review_comments.orders.empty.title': '没有可评价商品',
      'product_review_comments.orders.finished_review.title': '感谢您的评价！',
      'product_review_comments.orders.finished_review.desc.user_credit':
        '恭喜你，稍候即会获得评价奖赏 {{ value }} 购物金。',
      'product_review_comments.orders.finished_review.desc.member_point':
        '恭喜你，稍候即会获得评价奖赏 {{ value }} 点数。',
      'product_review_comments.orders.max_length_of_characters': '2000字以內',
      'product_review_comments.orders.write_a_review': '评价',
      'product_review_comments.orders.upload_image': '上传图片（最多 9 张）',
      'product_review_comments.orders.completed_all_reviews':
        '恭喜！已完成所有评价！',
      'product_review_comments.orders.back_to_the_store': '去购物',

      'product.product_review.out_of_5_stars': '分',
      'product.product_review.rating_source_amazon': '评价来源：亚马逊',
      'product.product_review.rating_source_shopline': '评价来源：此店',
      'product.product_review.reviews': '个评价',
      'product.product_review.stars': '分',
      'product.product_review.no_review': '暂时没有评价',
      'product_review_entry.review_description_title': '我们在等待你的评价！',
      'product_review_entry.review_description_title.user_credit':
        '我们在等待你的评价！评价后立刻获得 {{ value }} 购物金！',
      'product_review_entry.review_description_title.member_point':
        '我们在等待你的评价！评价后立刻获得 {{ value }} 点数！',
      'product_review_entry.review_description_content':
        '对购买的商品满意吗？给我们一个好评吧！',
      'product_review_entry.review_button_text': '马上评价',

      'product.view_more': '查看更多',
      'product.view_less': '收起内容',

      'product.add_to_cart': '加入购物车',
      'product.add_to_cart_fail': '您的购物车已满！',
      'product.buy_now': '立即购买',
      'product.buy_now.preorder.hint': '商品预购中',
      'product.buy_together': '一同购买',
      'product.preorder_limit.hint': '购买数量包含现货及预购商品',
      'product.coming_soon': '即将开卖',
      'product.available_time_over': '贩售结束',
      'product.store_stock.check_stock': '查看可取货门市',
      'product.set.open_variation': '查看商品详情',

      'member.center.load.more': '载入更多',
      'member.center.load.more.error': '未能载入资料，请重新再试。',

      'wishlist.signin_register': 'Sign in/up now',
      'wishlist.messages.remove_error': '无法成功删除追踪商品。',
      'back_in_stock.subscibe.success.message': '当商品重新上架时，会发送通知',
      'back_in_stock.added': '已订阅货到通知',
      'back_in_stock.avaiable': '货到通知我',

      orders: '订单',
      order: '订单',
      'orders.fields.billing_address': '账单地址',
      'orders.fields.order_number': '订单号码',
      'orders.fields.order_date': '订单日期',
      'orders.fields.order_status': '订单状态',
      'orders.fields.order_remarks': '顾客订单备注',
      'orders.fields.customer_name': '顾客名称',
      'orders.fields.product_subscription_period': '第{{period}}期',
      'orders.fields.status.temp': '订单处理中',
      'orders.fields.status.pending': '订单处理中',
      'orders.fields.status.confirmed': '已确认',
      'orders.fields.status.shipped': '已发货',
      'orders.fields.status.completed': '已完成',
      'orders.fields.status.received': '已收货',
      'orders.fields.status.cancelled': '已取消',
      'orders.fields.status.returning': '退货中',
      'orders.fields.status.returned': '已退货',
      'orders.fields.customer': '顾客',
      'orders.fields.preorder_item': '预购商品',
      'orders.fields.customer.phone': '顾客电话号码',
      'orders.fields.billto': '账单',
      'orders.fields.payment_type': '付款方式',
      'orders.fields.payment_instructions': '付款指示',

      'orders.fields.order_delivery.pending': '备货中',
      'orders.fields.order_delivery.shipping': '发货中',
      'orders.fields.order_delivery.shipped': '已发货',
      'orders.fields.order_delivery.arrived': '已到达',
      'orders.fields.order_delivery.collected': '已取货',
      'orders.fields.order_delivery.returning': '退货中',
      'orders.fields.order_delivery.returned': '已退货',

      'orders.fields.payment_method.validation.limit_maximum.cvs':
        '交易失败：超商代码的最高收款金额为NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.cvs':
        '交易失败：超商代码的最低收款金额为NT$30',
      'orders.fields.payment_method.validation.limit_maximum.barcode':
        '交易失败：超商条码的最高收款金额为NT$20000',
      'orders.fields.payment_method.validation.limit_lowest.barcode':
        '交易失败：超商条码的最低收款金额为NT$30',

      'orders.fields.payment_method.validation.limit_exceeded.allpay_cvs':
        '超商代码单笔收款上限为30,000新台币',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_barcode':
        '超商条码单笔收款上限为20,000新台币',
      'orders.fields.payment_method.validation.limit_exceeded.allpay_webatm':
        '网路ATM单笔收款上限为30,000新台币',
      'orders.fields.delivery_method': '送货方式',
      'orders.fields.delivery_description': '送货方式指示',
      'orders.fields.delivery_fee': '运费',
      'orders.fields.delivery_address': '送货地址',
      'orders.fields.delivery_address.remarks': '送货备注',
      'orders.fields.options.blacklist.error':
        '您的商品不适用於此送货方式或付款方式，请再查看商品资讯，选择其他选项或分开下单。',
      'address.fields.address': '地址',
      'address.fields.city': '城市',
      'address.fields.postcode': '邮政编号',
      'address.fields.address.state': '地区/州/省份',
      'address.fields.country': '国家/地区',

      'orders.fields.subtotal': '小计',
      'orders.fields.total': '合计',
      'order.billing_address.same': '与送货地址相同',

      'orders.coupons.label': '优惠券代码',
      'orders.coupons': '恭喜！你可以享用以下优惠：',
      'orders.coupons.placeholder': '请输入优惠券代码',
      'orders.promotion_coupons.placeholder': '请输入优惠代码',

      'orders.coupons.apply': '使用',
      'orders.fields.order_discount': '折扣',
      'orders.fields.order_custom_discount': '自订折扣合计',
      'orders.coupons.invalid': '对不起，你的优惠卷不适用。',
      'orders.coupons.validation.minamount':
        '对不起, 你的订单未达到最低消费，优惠卷不适用。',
      'orders.coupons.invalid.order': '结帐前请先删除无效的优惠卷或代码',

      'orders.actions.title': '管理订单状态',
      'orders.action.cancel': '取消订单',
      'orders.action.confirm': '确认订单',
      'orders.action.ship': '标示为已发货',
      'orders.general.title': '订单资料',
      'orders.payment.title': '付款资料',
      'orders.delivery.title': '送货／物流资料',
      'orders.products.title': '商品详情',

      'orders.fields.accept_terms': '我同意细则和私隐条款',
      'orders.fields.accept_terms.validation.required':
        '你必须同意细则和私隐条款',
      'orders.show.message.thankyou.title': '谢谢你的订单',
      'orders.show.message.thankyou.description': ' ',
      'orders.show.thankyou.continue': '继续购物！',
      'orders.show.message.shipped.title': '你的订单已经寄出',
      'orders.show.message.shipped.description': ' ',

      'orders.payment_slip.order_info.amount': '订单金额 ',
      'orders.payment_slip.order_info.instructions': '付款说明',
      'orders.payment_slip.order_info.read_more': '查看完整说明',
      'orders.payment_slip.upload_form.title': '完成转帐后，请上传转帐明细',
      'orders.payment_slip.upload_form.hint':
        '确认款项后，我们将尽速安排发货！',
      'orders.payment_slip.upload_form.notification':
        '想取得最新订单资讯，请订阅',
      'orders.payment_slip.upload_form.image_input.title': '上传转帐明细',
      'orders.payment_slip.upload_form.image_input.description':
        '请确认图片清晰，并包含转帐后五码、转帐时间、转帐金额',
      'orders.payment_slip.upload_form.image_input.change': '点击更换照片',
      'orders.payment_slip.upload_form.image_input.error': '图片需小于 10MB',
      'orders.payment_slip.upload_form.paid_time': '选择付款时间',
      'orders.payment_slip.upload_form.message': '填写付款备注',
      'orders.payment_slip.upload_form.message.error':
        '长度不可超过 {{ n }} 字',
      'orders.payment_slip.uploaded.alert':
        '谢谢您，已上传转帐明细，我们将尽速确认！',
      'orders.payment_slip.uploaded.notification': '订阅获得最新订单资讯',
      'orders.payment_slip.uploaded.title': '以下是您的转帐明细',
      'orders.payment_slip.uploaded.paid_time': '付款时间:',
      'orders.payment_slip.uploaded.upload_time': '上传明细时间:',
      'orders.payment_slip.uploaded.order_link': '查看订单',
      'orders.payment_slip.uploaded_without_login.title':
        '谢谢您！此订单已上传付款明细',
      'orders.payment_slip.uploaded_without_login.hint': '您可以登入查看明细',
      'orders.payment_slip.footer':
        '您填写的付款资讯仅用于订单对帐并经过加密技术，敬请安心付款。',

      // customer cancel order section
      'orders.show.check_order.title': '查看订单',
      'orders.show.check_order.description':
        '您可以到 <a href="{{pagePath}}">会员中心 > 订单</a> 里查看或取消订单',
      'orders.show.customer_cancel_order.confirm.title': '您确定要取消订单吗？',
      'orders.show.customer_cancel_order.confirm.description.integrated':
        '请告诉我们取消的原因，我们会努力做得更好！<br>成功取消后将会进行退款。期待您再度光临!',
      'orders.show.customer_cancel_order.confirm.description.non_integrated':
        '请告诉我们取消的原因，我们会努力做得更好！<br>敬请耐心等候，我们将尽快处理。',
      'orders.show.customer_cancel_order.confirm.loading.title': '正在处理',
      'orders.show.customer_cancel_order.confirm.loading.description':
        '请耐心等候，不要关闭页面或返回上一页',
      'orders.show.customer_cancel_order.confirm.success.title':
        '取消申请已送出',
      'orders.show.customer_cancel_order.confirm.success.description':
        '取消成功后将寄电邮通知，谢谢您！',
      'orders.show.customer_cancel_order.confirm.failed.title':
        '取消订单失败，请再试一次',
      'orders.show.customer_cancel_order.confirm.failed.description':
        '若重试失败，请于下方的「卖家和顾客订单通讯」与我们联络。',
      'orders.show.customer_cancel_order.button.cancel': '取消订单',
      'orders.show.customer_cancel_order.button.ok': '确定',
      // customer cancel order reasons
      'orders.show.customer_cancel_order.reasons.default': '请选择取消原因',
      'orders.show.customer_cancel_order.reasons.change_mind': '改变心意',
      'orders.show.customer_cancel_order.reasons.expensive_price':
        '价格比其他地方贵',
      'orders.show.customer_cancel_order.reasons.repeat_purchase': '重複购买',
      'orders.show.customer_cancel_order.reasons.wait_too_long': '出货太慢',
      'orders.show.customer_cancel_order.reasons.change_item': '想变更购买项目',
      'orders.show.customer_cancel_order.reasons.incorrect_customer_info':
        '送货或付款资讯填写错误',
      'orders.show.customer_cancel_order.reasons.other': '其他原因',
      'orders.show.customer_cancel_order.reasons.other.placeholder':
        '请告诉我们原因，让我们下次做得更好！',
      'orders.show.customer_cancel_order.reasons.hint': '取消原因',

      'orders.index.no_records.title': '你没有任何订单',
      'orders.index.no_records.description': '你在这个商店没有订单。',
      'orders.index.no_records.action': '继续购物！',
      'orders.index.title': '订单',
      'orders.index.action.view': '查阅',
      'orders.action.checkout': '前往结帐',

      'orders.fields.delivery_data.tw_simple_711.recipient_name.label':
        '收件人',
      'orders.fields.delivery_data.tw_simple_711.location_name.label':
        '7-11 门市名称',
      'orders.fields.delivery_data.tw_simple_711.location_code.label':
        '7-11 店号',

      'orders.fields.delivery_data.tw_simple_familymart.recipient_name.label':
        '收件人',
      'orders.fields.delivery_data.tw_simple_familymart.location_code.label':
        '全家服务编号 (不是店铺号)',
      'orders.fields.delivery_data.tw_simple_familymart.location_name.label':
        '全家门市名称',

      'orders.fields.delivery_data.sl_logistics_yto_store.seven_eleven.label':
        '7-11',
      'orders.fields.delivery_data.sl_logistics_yto_store.family_mart.label':
        '全家',
      'orders.fields.delivery_data.sl_logistics_yto_store.store.label': '门市',
      'orders.fields.delivery_data.sl_logistics_yto_store.city_list.error':
        '送货方式发生错误，请重新整理或<a href="/cart" class="alert-highlight">返回上一页更换送货方式</a>',
      'orders.fields.delivery_data.sl_logistics_yto_store.store_dropdown.error':
        '地址发生错误，请重新选择',

      'orders.fields.delivery_data.family_mart_freeze.error.locale_code':
        '配送编号门市是必须的',

      'checkout_object.detail.close': '关闭',
      'checkout_object.detail.title': '消费金额明细',

      'orders.promotion_type.promotion': '优惠促销',
      'orders.promotion_type.reward_credit': '满额送购物金',
      'orders.promotion_type.bundle_pricing': '任选优惠',
      'orders.promotion_type.bundle_percentage': '任选优惠',
      'orders.promotion_type.bundle_amount': '任选优惠',
      'orders.promotion_type.bundle_gift': '任选优惠',
      'orders.promotion_type.bundle_group': 'A+B 组合',
      'orders.promotion_type.bundle_group_percentage': 'A+B 组合',
      'orders.promotion_type.bundle_group_amount': 'A+B 组合',
      'orders.promotion_type.bundle_group_gift': 'A+B 组合',
      'orders.promotion_type.buyandget_free': '买Ｘ享Ｙ',
      'orders.promotion_type.buyandget_pricing': '买Ｘ享Ｙ',
      'orders.promotion_type.buyandget_percentage': '买Ｘ享Ｙ',
      'orders.promotion_type.coupon': '优惠代码',
      'orders.promotion_type.membership_offer': '会员优惠',
      'orders.promotion_type.custom_discount': '自订折扣',
      'orders.promotion_type.member_point_redeem_gift': '兑换活动',
      'orders.promotion_type.free_shipping': '免运优惠',
      'orders.promotion_type.credit_reward': '购物金回馈',
      'orders.promotion_type.point_reward': '点数回馈',
      'orders.promotion_type.earn_purchase_points': '消费集点',

      'orders.reward_credit.value': '{{ credit_value }} 购物金',
      'orders.reward_credit.auto_reward':
        '满 {{ credit_threshold }} 送 {{ credit_value }} 购物金',
      'orders.reward_credit.accumulated_auto_reward':
        '每 {{ credit_threshold }} 送 {{ credit_value }} 购物金',
      'orders.reward_credit.balance': '还差 {{ auto_reward_balance }}',
      'orders.reward_credit.faq':
        '赠送的购物金将在付款完成后获得，可在下次购物使用！',
      'orders.reward_credit.earned_after_order_completed_faq':
        '提醒您此活动以商品折扣后金额计算回馈，订单完成后即可获得购物金，并于下次购物时使用！',

      'orders.reward_point.value': '{{ point_value }} 点',
      'orders.reward_point.faq':
        '赠送的点数将在订单完成后获得，可在下次购物使用！',
      'orders.reward_point.earned_after_order_completed_faq':
        '提醒您此活动以商品折扣后金额计算回馈，订单完成后即可获得点数，并于下次购物时使用！',

      'orders.fields.applied_user_credits': '折抵购物金',
      'orders.fields.applied_member_point_redeem_to_cash': '使用点数折现',
      'orders.fields.delivery_fee_free': '免运',
      'orders.fields.remaining_member_points_without_balance':
        '本次使用 {{ applied }} 点',
      'orders.fields.user_credits': '购物金',
      'orders.fields.member_points': '点',
      'orders.fields.rounding': '进位/舍去',
      'orders.fields.credits_earned_after_paid': '订单完成后获得购物金',

      'orders.member_point.fields.redeemed': '点数兑换赠品',
      'orders.member_point.unit': '点',
      'orders.member_point.settled_after_order_completed': '订单完成后获得点数',
      'orders.member_point.pos_settled_content':
        '实体店订单完成后约 {{ pending_days }} 天获得点数',
      'orders.member_point.settled_after_content':
        '取货後 {{ pending_days }} 天获得点数',

      // Checkout page error message field name
      'order.customer_name': '顾客名称',
      'order.customer_email': '顾客电邮',
      'order.email': '电子信箱',
      'order.customer_phone': '顾客电话号码',
      'order.delivery_data.recipient_name': '收件人名称',
      'order.delivery_data.recipient_phone': '收件人电话号码',
      'order.delivery_data.consignee_id_no': '收件人身份证字号或护照号码',
      'order.delivery_address.address_1': '地址',
      'order.delivery_address.city': '城市/市镇',
      'order.delivery_address.postcode': '邮递区号',
      'order.delivery_address.state': '地区/州/省份',
      'order.delivery_data.location_code': '门市编号',
      'order.delivery_data.location_name': '门市名称',
      'order.delivery_data.time_slot': '送货时间',
      'order.delivery_data.scheduled_delivery_date': '选择指定到货日期',
      'order.delivery_data.time_slot_key': '选择指定到货时段',
      'order.invoice.carrier_number': '条码',
      'order.invoice.mailing_address': '发票地址',
      'order.invoice.tax_id': '统一编号',
      'order.payment_data.holdername': '持卡人名字 ',
      'order.payment_data.expiry_date': '有效日期',
      'order.payment_data.cvc': '安全码(CVC)',
      'order.payment_data.credit_card_number': '信用卡号码',
      'order.billing_address.title': '帐单寄送地址',

      'lock_inventory.locked_quantity_unit': '件',

      'delivery.sfexpress.district': '顺丰地区',
      'delivery.sfexpress.store': '顺丰地址',
      'delivery.sfexpress.locker_location': '智能柜地区',
      'delivery.sfexpress.ef_locker': '顺便智能柜',
      'delivery_address.region.hk': '地区',
      'delivery_address.district.hk': '区域',
      'delivery_address.region.tw': '城市/县',
      'delivery_address.district.tw': '地区',
      'save_fields.customer_info.birthday': '顾客生日日期',
      'delivery_address.province.vn': '省/市',
      'delivery_address.district.vn': '郡/鎮',
      'delivery_address.ward.vn': '社/坊',
      'delivery_address.province.th': '府',
      'delivery_address.district.th': '县/郡',
      'delivery_address.ward.th': '区',
      'delivery_address.street': '街道',
      'delivery_address.store': '{{ station_name }}门市',

      'delivery_options.fields.delivery_type': '物流类别',
      'delivery_options.fields.delivery_types.pickup': '面交／自取',
      'delivery_options.fields.delivery_types.local': '本地邮寄',
      'delivery_options.fields.delivery_types.email': '电邮',
      'delivery_options.fields.delivery_types.international': '海外邮寄',
      'delivery_options.fields.delivery_types.custom': '自定',
      'delivery_options.fields.delivery_types.tw_simple_711':
        '7-11取货（只限台湾）',
      'delivery_options.fields.delivery_types.tw_simple_familymart':
        '全家取货（只限台湾）',
      'delivery_options.fields.select_date': '选择指定到货日期',
      'orders.fields.delivery_data.tw_ezship.location_code.hint': '搜寻门市',
      'orders.fields.delivery_data.tw_ezship.location_code.label':
        '收件门市代号',
      'orders.fields.delivery_data.tw_ezship.location_name.label':
        '收件门市名称',
      'orders.fields.delivery_data.tw_ezship.recipient_name.label': '收件人',
      'orders.fields.delivery_data.tw_ezship.sn_id.label': '店到店编号',

      'delivery_fee.pay.on.delivery': '运费于到货时向您收取',

      'ezship.error.invalid_input': '内容有误或栏位短缺',
      'ezship.error.account_not_exist': 'ezShip帐号不存在',
      'ezship.error.no_permission': 'ezShip帐号权限不足',
      'ezship.error.no_easybag': 'ezShip帐号无可用之轻松袋或迷你袋',
      'ezship.error.invalid_store': '取件门市有误',
      'ezship.error.invalid_amount':
        '金额有误。ezShip单一帐单代收金额上限为新台币6000元。',
      'ezship.error.invalid_email': '电邮格式有误',
      'ezship.error.invalid_mobile': '电话号码格式有误',
      'ezship.error.invalid_name': '收件人内容有误或为空值',
      'ezship.error.system_error':
        'ezShip系统错误，请选取其他送货方式或稍後再试。',

      'payments.fields.types.allpay_barcode': '超商条码（经由欧付宝）',
      'payments.fields.types.allpay_cvs': '超商代码（经由欧付宝）',
      'payments.fields.types.allpay_credit': '信用卡（经由欧付宝）',
      'payments.fields.types.allpay_webatm': '网络ATM（经由欧付宝）',
      'payments.fields.types.asiapay': '信用卡（经由Asiapay）',
      'payments.fields.types.paypal': '信用卡或Paypal',
      'payments.fields.types.paypal_express': '信用卡或Paypal',
      'payments.fields.types.credit_card': '信用卡',
      'payments.fields.types.bank_transfer': '银行转帐／ATM',
      'payments.fields.types.cash_on_delivery': '货到付款',
      'payments.fields.types.ezship': '超商货到付款（经由ezShip）',
      'payments.fields.types.free_checkout': '免费结帐',
      'payments.fields.types.custom': '自定付款方法',
      'payments.fields.types.prizm': '信用咭 Visa / Master',
      'payments.fields.types.esun': '玉山信用卡',

      'payment.cc.expirydate': '有效日期',
      'payment.cc.holdername': '持卡人名字',
      'payment.cc.number': '信用卡号码 (Visa / Master)',
      'payment.cc.cvc': '安全码(CVC)',
      'payment.cc.expirydate.placeholder': 'MM/YY',

      'payment.cc.holdername.placeholder': '持卡人名字',
      'payment.cc.number.placeholder': '信用卡号码(不留空格）',
      'payment.cc.cvc.placeholder': '安全码(CVC)',
      'payment.action.confirm': '确定',
      'payment.cc.update.instructions': '请输入并更新你的信用卡资料。',
      'payment.cc.fields.accept_terms': '我同意细则和私隐条款。',
      'payment.cc.fields.accept_terms.validation.required':
        '你必须同意细则和私隐条款。',
      'payment.cc.cashier.footer': '金流服务由SHOPLINE Payments提供',
      'payment.cc.tw.cashier.footer':
        '本金流服务由SHOPLINE Payments提供，通过PCI-DSS国际信用卡组织最高级别认证，提供安全的交易服务，支持信用卡信用卡刷卡。',

      cart: '购物车',
      'cart.title': '购物车',
      'cart.clean': '立马结账！',
      'cart.over_limit':
        '购物车商品数量超出 <span class="limit-number">{{limit_number}}</span> 件的上限，请先结账后再加入商品！同时，您也可以登入会员将此商品加入追踪清单。',
      'cart.over_limit.login':
        '购物车商品数量超出 <span class="limit-number">{{limit_number}}</span> 件的上限，请先结账后再加入商品！同时，您也可以将此商品加入追踪清单。',
      'cart.item.remove': '移除',
      'cart.items.remove': '移除',
      'cart.checkout': '订单结帐',
      'cart.empty': '你的购物车是空的',
      'cart.empty.description': '记得加入商品到你的购物车',
      'cart.empty.continue': '继续购物！',
      'cart.promotion.checkout': '前往购物车',
      'cart.promotion.has_items_selected': '已选 <b>{{quantity}}</b> 件',
      'cart.promotion.bundle_group.has_items_selected':
        'A 组已选 <b style="color: #f05c53">{{red_quantity}}</b> 件{{red_reminder}}，B 组已选 <b style="color: #1ba462">{{green_quantity}}</b> 件{{green_reminder}}{{applied_reminder}}',
      'cart.promotion.bundle_group.has_items_selected.mobile':
        'A 组 <b style="color: #f05c53">{{red_quantity}}</b> 件，B 组 <b style="color: #1ba462">{{green_quantity}}</b> 件',

      'cart.redeem_gift.has_items_selected':
        '兑换赠品 <span>{{total_point}}</span> 点，剩 <span>{{rest_point}}</span> 点可兑换',
      'cart.redeem_gift.has_items_selected.insufficient_point':
        '已超出可兑换点数 <span>{{rest_point}}</span> 点，请移除部分赠品',

      'cart.promotion_reminder.separator': '，',
      'cart.promotion_reminder.group_red':
        '<span class="bundle-group-label bundle-group-red">A 组</span> 还差 {{gap}} 件',
      'cart.promotion_reminder.group_green':
        '<span class="bundle-group-label bundle-group-green">B 组</span> 还差 {{gap}} 件',
      'cart.promotion_reminder.group_gap': ' (还差 {{gap}} 件)',
      'cart.promotion_reminder.item': '再买 {{gap}} 件 ',
      'cart.promotion_reminder.amount': '再买 {{gap}} ',
      'cart.promotion_reminder.multiple_step': '省更多！',
      'cart.promotion_reminder.multiple_step.reward_campaign': '回馈更多',
      'cart.promotion_reminder.stackable': '买越多省越多！',
      'cart.promotion_reminder.applied.free_shipping': '恭喜享有免运',
      'cart.promotion_reminder.applied.gift': '恭喜享有赠品',
      'cart.promotion_reminder.applied.discount_prefix': '，',
      'cart.promotion_reminder.applied.discount': '恭喜享有优惠，',
      'cart.promotion_reminder.applied.reward_campaign': '恭喜享有优惠，',
      'cart.promotion_reminder.applied_highest.free_shipping': '恭喜享有免运！',
      'cart.promotion_reminder.applied_highest.gift': '恭喜享有赠品！',
      'cart.promotion_reminder.applied_highest.discount': '恭喜享有优惠！',
      'cart.promotion_reminder.applied_highest.reward_campaign':
        '恭喜享有优惠！',
      'cart.promotion_reminder.discount_target.free_shipping': '即享有免运！',
      'cart.promotion_reminder.discount_target.gift': '即享有赠品！',
      'cart.promotion_reminder.discount_target.reward_campaign': '即享有優惠！',
      'cart.promotion_reminder.discount_target.discount': '即享有优惠！',
      'cart.lock_cart_sc_product.description':
        '此为直播/贴文销售商品，不可修改购买数量',
      'cart.lock_cart_sc_product.remove_hint':
        '此为直播/贴文销售商品，不可删除',
      'cart.coupon_code.apply_success': '已使用优惠券',
      'cart.affiliate_code.apply_success': '推荐人已套用',

      'checkout.instructions': '订单资料',
      'checkout.fields.email': '你的电邮',
      'checkout.fields.email.hint':
        '请确保正确填入电邮，所有订单事宜（确认丶查询）都会传送至您的电邮。',
      'checkout.fields.phone.hint': '请输入手机号码，以接收物流短讯通知',
      'checkout.fields.email.placeholder': '电邮',
      'checkout.fields.phone': '你的电话号码',
      'checkout.fields.phone.placeholder': '电话号码',
      'checkout.fields.remarks': '订单备注',
      'checkout.fields.remarks.placeholder': '请填写备注给店主',
      'checkout.fields.delivery_method': '送货方式',
      'checkout.delivery_option.title': '送货地址',
      'checkout.delivery_option.out_of_stock':
        '部分商品库存不足，无法选择此门市，请尝试更换其他门市',
      'checkout.payment_method.title': '付款资料',
      'checkout.fields.out_of_stock':
        '对不起，所选的货品存货不足，请先移除然後再下单',
      'checkout.fields.add_new': '新增',
      'checkout.fields.select': '--选择--',
      'checkout.instalment_amount': '大约为{{amount}} x {{period}}期',
      'checkout.labels.promotion.free_shipping': '免运',
      'checkout.labels.promotion.coupon': '优惠券',
      'checkout.labels.promotion.promotion': '优惠促销',
      'checkout.labels.promotion.membership': '会员优惠',

      'checkout.discount.applied_promotions': '已享用之优惠',
      'checkout.discount.applied_coupons': '已享用之优惠',

      'checkout.promotion.tags.promotion': '优惠促销',
      'checkout.promotion.tags.coupon': '优惠券',
      'checkout.promotion.tags.membership': '会员优惠',
      'checkout.promotion.tags.free_shipping': '免运',
      'checkout.promotion.tags.subscription_promotion': '定期购优惠',

      'checkout.payment_condition.title': '还差 {{difference}}！',
      'checkout.payment_condition.description':
        '才能进行结帐，请返回购物车选择其他付款方式或者继续购物',
      'checkout.payment_condition.continue_shopping': '继续购物',
      'checkout.payment_condition.hint': '此订单须符合最低金额才能进行结帐',
      'checkout.payments.exist.order.tip':
        '稍早已有成立订单 <a href="{{ duplicateOrderLink }}">{ {{order}} }</a>，如需再次结帐可点击提交订单。',

      'checkout.payments.apple_pay.not_supported':
        '无法以 Apple Pay支付：<ul><li>请确认装置或浏览器是否已登入 Apple 帐户</li><li>请确认 Apple Pay 帐户内是否已绑定有效卡号</li>' +
        '<li>请确认装置或浏览器是否为最新版本且支援 Apple Pay</li></ul>',
      'checkout.payments.google_pay.not_supported':
        '无法以 Google Pay支付：<ul><li>请确认装置或浏览器是否已登入 Google 帐户</li><li>请确认 Google Pay 帐户内是否已绑定有效卡号</li>' +
        '<li>请确认装置或浏览器是否为最新版本且支援 Google Pay</li></ul>',
      'checkout.payment_failed': '付款失败，请重新再试',
      'checkout.payment_failed.atome.minamount':
        '订单金额不符付款方式限额，请调整购物车内容或切换其他付款方式',
      'checkout.payment_failed.check_credit_card_info':
        '请先确认卡片资讯，如确认输入资料正确，请向发卡行确认卡片授权状况或联系店家',
      'checkout.cart_over_limit_error':
        '商品总数的上限为 100 件，请调整数量再点击购买',
      'checkout.card_info_error': '请确认信用卡资讯正确后再试一次',
      'checkout.station_space_confirm_error':
        '门市选择失败，请再试一次或是联络我们',
      'checkout.payments_transaction_error':
        '你的交易出现问题，请重新检查输入的付款资料，或与发卡行查询。',
      'checkout.card_type_not_support_error': '暂不支援您使用的信用卡别种类',

      'checkout.auto_fill.popup.title': '输入手机以进行快速结帐',
      'checkout.auto_fill.popup.continue': '继续',
      'checkout.auto_fill.popup.error': '请输入正确的格式',
      'checkout.auto_fill.popup.cancel': '取消',
      'checkout.auto_fill.toast.error':
        '没有侦测到上一笔订单的纪录，将不会带入快速结帐资料',

      'address.fields.recipient_name': '收件人',
      'address.fields.address.recipient_name': '收件人',
      'address.fields.address.city': '城市',
      'address.fields.address.postcode': '邮政编号（如适用)',
      'address.fields.address.country': '国家/地区',
      'address.fields.address.remarks': '备注',

      'messages.form.send': '发送',
      'action.add_photo': '加入图片',
      'action.send': '发送',

      'action.load_earlier': '载入之前',
      'action.load_more': '载入更多',
      'users.fields.name': '用户名',
      'users.fields.email': '电邮',
      'users.fields.mobile': '手机号码',
      'users.fields.mobile_phone_or_email': '电邮或手机号码',
      'users.fields.password': '密码',
      'users.fields.name.placeholder': '用户名',
      'users.fields.email.placeholder': '电邮',
      'users.fields.mobile.placeholder': '手机号码',
      'users.fields.password.placeholder': '密码',
      'users.save.success': '变更已储存',
      'users.fields.callingCode': '区号',
      'users.fields.callingCodeHint': '请选择国际电话区号',
      'users.save.email_verification.success':
        '变更已储存, 电邮会在验证成功后更新',
      'users.save.email_verification.send': '验证信已寄出',
      'users.add.phone': '新增联络电话',
      'users.add.delivery_address': '新增送货地址',
      'users.add.phone.maximum': '最多只能有五组电话号码',
      'users.add.delivery_address.maximum': '最多只能有五个送货地址',
      'users.birthday.faq': '您需达到一定年龄才能在网店注册及购物。',
      'users.error.not_save_mobile_phone': '此手机号码尚未验证，无法储存变更',
      'users.error.no_changes': '没有任何可储存的变更',
      'users.signin.error.invalid.mobile': '帐号或密码是无效的',
      'users.signin.error.invalid.email': '帐号或密码是无效的',
      'users.signup.male': '男',
      'users.signup.female': '女',
      'users.signup.other': '不透露',
      'users.signup.email': '电邮',
      'users.signup.mobile_phone': '手机',
      'users.signup.unconfirmed':
        '欢迎回来！为保护您的帐号安全，即日起需完成帐号验证才能登入。验证信已发送至{{email}}，请前往收信验证，谢谢您！',
      'users.signup.verification.hint.send':
        '验证信已寄出，{{count}}秒后可重发验证信',
      'users.einvoice_carrier': '电子发票载具归户',
      'users.edit_member_info': '编辑会员资料',
      'users.membership.member_info.title': '会员信息奖赏',
      'users.membership.member_info.hint':
        '填写以下会员信息：{{fields}}，填写完毕可获得 {{rewards}}',
      'users.membership.member_info.sent':
        '待信息检查完成，将发放会员信息奖赏！',
      'users.membership.member_info.symbol': '、',
      'users.membership.member_info.name': '姓名',
      'users.membership.member_info.email': '电邮',
      'users.membership.member_info.mobile': '手机号',
      'users.membership.member_info.gender': '性别',
      'users.membership.member_info.birthday': '生日',
      'users.membership.member_info.user_credits': '${{count}} 元购物金',
      'users.membership.member_info.member_points': '{{count}} 点会员点数',
      'users.membership.member_info.coupons': '{{count}} 张优惠券',
      'users.membership.forget_password.email_sent':
        '我们已寄出帐号验证信至{{email}}，\n请收信以继续重设密码！',
      'users.member.normal-level': '一般会员',
      'users.membership.expiry_date': '会籍到期日',
      'users.membership.expire.unlimited': '永久有效',
      'users.membership.expire.month_valid': '（效期 {{months}} 个月)',
      'users.membership.tier.expire.date.description':
        '自动更新时间为订单完成付款后的隔日凌晨，若因取消订单或退货未达成升级条件则不会升级。',
      'users.membership.tier.expire.date.auto_downgrade.description':
        '自动更新时间为订单转为已完成后的隔日凌晨，若因取消订单或退货未达成升级条件则不会升级。',
      'users.membership.tier.extension.date.description':
        '会籍到期时，订单状态若因取消订单或退货未达成续会条件则不会续会。',
      'users.membership.offer.discount': '专属优惠',
      'users.membership.upgrade.next_tier': '升级至 {{tier_name}}',
      'users.membership.upgrade.next_tier.discount': '，享购物 {{discount}} 折',
      'users.membership.extension.condition': '续会条件',
      'users.membership.extension.status': '续会状态',
      'users.membership.max_level_tier': '恭喜您已至最高阶会员!',
      'users.membership.spending_within_months':
        '{{months}} 个月内累积消费额达 {{spending}} 即可升级',
      'users.membership.spending_single_purchase':
        '单次消费额达 {{spending}} 即可升级',
      'users.membership.all.spending_within_months':
        '{{months}} 个月内累积消费额达 {{spending}}',
      'users.membership.all.spending_single_purchase':
        '单次消费额达 {{spending}}',
      'users.membership.extend_valid_period':
        '会籍效期{{months}}个月内{{ type === "within_interval" ? "累积" : "单次" }}消费额达 {{ spending }}',
      'users.membership.all_tiers': '查看所有会员等级规则',
      'users.membership.all_tiers.condition': '会员等级规则',
      'users.membership.all_tiers.description':
        '会籍期效内若没达成续会条件，将会自动降级',
      'users.membership.tier.shopping_discount': '购物 {{discount}} 折',
      'users.membership.tier.upgrade.condition': '本阶升级条件 :',
      'users.membership.tier.upgrade.condition.dollar':
        '目前没有高于 {{ dollar }} 的订单，',
      'users.membership.tier.upgrade.condition.continue': '继续逛逛',
      'users.membership.tier.extend': '已达 <b>{{tier}}</b> 续会条件？',
      'users.membership.tier.upgrade.to_next_level.single': '已达升级条件',
      'users.membership.tier.upgrade.to_next_level.within':
        '{{month}} 个月内累积消费额',
      'users.membership.tier.upgrade.completed': '符合升级资格',
      'users.membership.tier.upgrade.gap_dollar': '差 {{ dollar }} 升级',
      'users.membership.tier.expire.date': '有效期限 :',
      'users.membership.tier.extend.condition': '续会条件 :',
      'users.membership.tier.extend.single_purchase':
        '会籍效期 {{month}} 个月内没有高于 {{dollar}} 的订单',
      'users.membership.tier.extend.single_purchase.can_extend':
        '会籍效期 {{month}} 个月内有高于 {{dollar}} 的订单，到期时将自动续会',
      'users.membership.tier.extend.top_level.single_purchase':
        '会籍内没有高于 {{dollar}} 的订单，',
      'users.membership.tier.extend.top_level.single_purchase.can_extend':
        '会籍内有高于 {{dollar}} 的订单，恭喜达成！到期时将自动续会',
      'users.membership.tier.extend.within_interval':
        '会籍效期 {{month}} 个月内累积消费 {{spending}}，差 {{dollar}} 续会',
      'users.membership.tier.extend.within_interval.can_extend':
        '会籍效期 {{month}} 个月内累积消费已达 {{dollar}}，到期时将自动续会',
      'users.membership.tier.extend.top_level.within_interval':
        '差 {{dollar}} 续会',
      'users.membership.tier.extend.top_level.within_interval.can_extend':
        '恭喜达成！到期时将自动续会',
      'users.membership.tier.expire.month': '{{month}} 个月',

      'users.phone.migrate.notification':
        '为配合商店功能更新，顾客电话将在 2019/1/15 后仅保留最后一组。如您有保存多组电话，请确认最后一组电话是正确的',
      'membership.profile.subscriptions.orders.update.status':
        'Updates to my orders',
      'membership.profile.subscriptions.orders.comments.new':
        'New comments from the merchant on my orders',
      'membership.profile.subscriptions.messages.new':
        'New messages from the merchant',
      'membership.profile.subscriptions.marketing.news': '商店资讯及优惠方案',
      'membership.profile.subscriptions.none': 'None',
      'membership.profile.mobile.number.verified':
        '会员手机验证完成，并将取代已储存的电话号码以利辨识',
      'membership.profile.check.code.error':
        '验证码有误，请重新输入或重发验证码',
      'membership.profile.verification_hint':
        '若您更改电邮，储存后需进行验证，完成验证后电邮将会立即更新为 {{unconfirmed_email}}',
      'membership.profile.email_subscription.error':
        '若要订阅 Email，请先输入电邮后再订阅',
      'membership.profile.sms_subscription.error':
        '若要订阅 SMS，请先输入手机号码后再订阅',

      'member_points.title': '会员点数',
      'member_points.unit': '点',
      'member_points.point_balance': '现有总点数',
      'member_points.point_history': '点数记录',
      'member_points.fields.date': '日期',
      'member_points.rule': '点数规则说明',
      'member_points.display.expire_point': '点将于',
      'member_points.display.successive_expire_point': '点将于',
      'member_points.fields.expire': '到期',
      'member_points.fields.expired': '点数过期',
      'member_points.fields.successive_expire': '后陆续到期',
      'member_points.fields.remarks': '点数更动原因',
      'member_points.fields.value': '点数',
      'member_points.fields.expiry_date': '到期日',
      'member_points.fields.member_point_expired': '点数过期',
      'member_points.fields.auto_reward': '于订单',
      'member_points.fields.auto_reward_seconds': '获得点数',
      'member_points.no_data': '没有任何记录',

      'coupon.title': '优惠券',
      'coupon.valid': '可使用',
      'coupon.invalid': '已失效',
      'coupon.valid_until':
        '优惠至 <span class="coupon-period-time"><div class="coupon-period-tip">依据你的当地时区显示<br/>(GMT{{timeZone}})</div> {{time}} </span> 截止',
      'coupon.start_at':
        '优惠开始日期 <span class="coupon-period-time"><div class="coupon-period-tip">依据你的当地时区显示<br/>(GMT{{timeZone}})</div> {{time}} </span>',
      'coupon.one_time': '限用 1 次',
      'coupon.first_shoppers': '前 {{number}} 名适用',
      'coupon.reach_limit': '已额满',
      'coupon.expired': '已过期',
      'coupon.used': '已使用',
      'coupon.no_valid_coupon': '没有可使用的优惠券',
      'coupon.no_invalid_coupon': '没有已失效的优惠券',
      'coupon.no_comingSoon_coupon': '没有即将开始的优惠券',
      'coupon.no_available_coupon': '没有可领取的优惠券',
      'coupon.select_coupon': '选择优惠券',
      'coupon.confirm': '确认',
      'coupon.first_purchase_only': '首购限定',
      'coupon.comingSoon': '即将开始',
      'coupon.retail_store_only': '限门市',
      'coupon.online_store_only': '限网店',
      'coupon.online_and_selected_retail_store_only': '限网店/指定门市',
      'coupon.selected_retail_store_only': '限指定门市',
      'coupon.selected_retail_store_list': '指定门市清单',
      'coupon.login_to_receive': '登入领取优惠券',
      'coupon.login_or_register': '请点击下方按钮前往登入/注册页面',
      'coupon.usage_limit': '优惠已额满',
      'coupon.already_taken': '您已领取过',
      'coupon.is_expired': '优惠已过期',
      'coupon.not_eligible': '不符合优惠券资格',
      'coupon.unfit_first_purchase': '不符合优惠券的首次购买条件',
      'coupon.login': '登入',
      'coupon.close': '关闭',
      'coupon.success': '领取成功',
      'coupon.congratulation': '恭喜你领取成功！',
      'coupon.view': '查看优惠券',
      'coupon.only_tag': '限定',

      go_coupon_center: '前往领券中心',
      back_member_center: '回到会员中心',
      coupon_center: '领券中心',

      'store_credits.title': '商店购物金',
      'store_credits.credit_balance': '现有购物金',
      'store_credits.credit_history': '购物金纪录',
      'store_credits.fields.date': '日期',
      'store_credits.fields.remarks': '购物金项目',
      'store_credits.fields.value': '购物金款项',
      'store_credits.fields.expiry_date': '到期日',
      'store_credits.fields.balance': '购物金馀额',
      'store_credits.fields.order_prefix': '已使用购物金在此笔订单',
      'store_credits.fields.welcome_credit': '新加入会员购物金',
      'store_credits.fields.member_referral_credit': '推荐新顾客进行消费',
      'store_credits.fields.member_info_quick_completion_credit':
        '会员信息奖赏购物金',
      'store_credits.fields.welcome_member_referral_credit':
        '透过推荐连结注册成功',
      'store_credits.fields.birthday_credit': '生日购物金',
      'store_credits.fields.user_credit_expired': '购物金过期',
      'store_credits.fields.never_expires': '永不过期',
      'store_credits.fields.never_expires_point': '点永不过期',
      'store_credits.fields.display.never_expired': '永不过期',
      'store_credits.fields.auto_reward_prefix': '订单 ',
      'store_credits.fields.auto_reward': ' 获得购物金',
      'store_credits.fields.revert_credit': '回补购物金；已取消订单',
      'store_credits.fields.revert_credit.return': '回补购物金；已退货订单',
      'store_credits.fields.revert_credit.order_split': '回补购物金；来自拆单',
      'store_credits.fields.revert_credit.order_edit':
        '回补购物金；来自编辑订单',
      'store_credits.fields.revert_credit.return_order_revert':
        '回补购物金；来自退货',
      'store_credits.fields.product_review_reward':
        '完成订单 <a href="{{link}}">{{ orderNumber }}</a> 的评价',
      'store_credits.no_data': '没有任何记录',
      'store_credits.bulk_imported': '手动新增购物金',

      'member_points.fields.revert_member_point': '回补点数；已退货订单',
      'member_points.fields.revert_member_point.return': '回补点数；已取消订单',
      'member_points.fields.revert_member_point.order_split':
        '回补点数；来自拆单',
      'member_points.fields.revert_member_point.order_edit':
        '回补点数；来自编辑订单',
      'member_points.fields.revert_member_point.return_order_revert':
        '回补点数；来自退货',
      'member_points.fields.product_review_reward':
        '完成订单 <a href="{{link}}">{{ orderNumber }}</a> 的评价',
      'member_points.fields.member_info_reward': '会员信息奖赏会员点数',

      'tax.sales_tax': '消费税',
      'tax.sales_tax_rate': '消费税率',
      'tax.service_tax': '服务税',
      'tax.service_tax_rate': '服务税率',
      'tax.delivery_tax': '运费税',
      'tax.delivery_tax_rate': '运费税率',
      'tax.calculate_tax': '计算税金',
      'tax.calculate_tax.info.1': '请先按',
      'tax.calculate_tax.info.2': '后再结帐',
      'tax.calculation_title': '税费计算方式：',
      'tax.calculation_content': '(收税商品总价 - 优惠) * 税率 + 运费 * 税率',
      'tax.price_updated': '价格已更新',
      'tax.total': '合计',
      'tax.fee': '税费',
      'tax.product_price': '商品价格',
      'tax.discounts': '优惠',
      'tax.applied_store_credit': '折抵购物金',

      'dynamic.delivery.fee.no.state.notice':
        '运费将于填写送货地址后，于结帐页显示。',
      'dynamic.delivery.fee.no.state.info': '将于确认送货地址后显示运费',
      'dynamic.delivery.fee.calculate.title': '运费计算方式',
      'dynamic.delivery.fee.calculate.desc':
        '首 1 公斤运费 + (总重 - 1 公斤) * 续重运费',
      'dynamic.delivery.fee.calculate.hint': '总重未满 1 公斤以 1 公斤计算',
      'dynamic.delivery.fee.updated.notice': '运费已更新',
      'dynamic.delivery.fee.calculate.info': '运费合计：{{totalDeliveryFee}}',

      'error.404.title': '非常抱歉',
      'error.404.description': '网页不存在',
      'error.401.title': '非常抱歉',
      'error.401.description': '您没有权限进入此页面',
      'error.403.title': '非常抱歉',
      'error.403.description': '您没有权限进入此页面',
      'error.403.not_signed_in.message': ', 这可能是因为您还没有',
      'error.403.not_signed_in.anchor': '登入',

      'error.continue': '继续购物！',

      'orders.comments.title': '店家和顾客订单通讯',
      'orders.comments.text.name': '留言',
      'orders.comments.text.placeholder': '请输入留言',

      'orders.invoices.title': '发票',
      'orders.invoices.carrier': '共同性载具',
      'orders.invoices.carrier_type.member':
        '会员载具 (发票资讯会寄到您的电邮)',
      'orders.invoices.carrier_type.mobile_barcode': '手机条码',
      'orders.invoices.carrier_type.npc_barcode': '自然人凭证条码',
      'orders.invoices.get_invoice': '我要索取发票',

      'orders.notifications.title': '订单状态通知',

      'order.payments.login.confirm': '请登入后再进行重新付款或更新信用卡资讯!',

      'model.credit_card.title': '您的信用卡资讯',
      'model.credit_card.subtitle': '请输入您的信用卡资讯',
      'model.credit_card.choose_card': '请选择你需要使用的支付卡',
      'model.credit_card.use_new_card': '使用新卡',
      'model.slpayment.security_agreement':
        '本金流服务由 SHOPLINE Payments 提供，通过 PCI-DSS 国际信用卡组纤最高等级认证，提供安全的交易服务，支援国内外信用卡刷卡。',
      'model.slpayment.recurring_pay_agreement':
        'SHOPLINE将会使用本次交易讯息作为后续定期扣款，点击提交即同意 <a href="https://shopline.tw/about/product_subscription_agreements" target="_blank" rel="noopener">《定期购信用卡代扣协议》</a>',
      'model.update_credit_card_for_product_subscription.success':
        '已成功更新信用卡资讯',
      'model.update_credit_card_for_product_subscription.fail':
        '定期购付款资讯设定失败，请重新尝试',

      'sms_messenger_checkbox.subscribe': '订阅 SMS',

      'facebook_messenger_checkbox.subscribed': '已订阅',
      'facebook_messenger_checkbox.turn_off': '关闭',
      'facebook_messenger_checkbox.v2.subscribe': '订阅 Messenger',
      'facebook_messenger_checkbox.v2.subscribed': '已订阅 Messenger',
      'facebook_messenger_checkbox.subscribe': '传送到 Messenger',
      'facebook_messenger_checkbox.topics.ORDER_STATUS_UPDATE,DELIVERY_STATUS_UPDATE':
        '订单状态通知',
      'facebook_messenger_checkbox.topics.MARKETING': '商店资讯及优惠方案',
      'subscribe.facebook.dialog.title': '订阅 Facebook',
      'subscribe.facebook.dialog.info': `因应 Facebook 平台的讯息规范更新，须至 Messenger 完成订阅流程。请点按「前往订阅」，系统将自动开启 Messenger。<br />开启 Messenger 后，请登入并<span class="facebook-subscription-dialog-hint">点按对话中的「立即开始」按钮</span>再回到本页面，即可完成订阅。 （若您曾与此商店的粉丝专页对话过，开启对话后即视为订阅成功。）`,
      'subscribe.facebook.dialog.cancel': '取消',
      'subscribe.facebook.dialog.subscribe': '前往订阅',

      'line_messenger_checkbox.subscribe': '传送到 LINE',
      'line_messenger_checkbox.subscribed': '已订阅 LINE',
      'line_messenger_checkbox.order_notification.subscribed':
        '已订阅订单状态通知',
      'line_messenger_checkbox.button.unsubscribe': '关闭',

      'whatsapp_messenger_checkbox.subscribe': '订阅 WhatsApp',

      'orders.fields.payment_fee': '附加费',

      'media.validation.file_limit.one': '只可以上载一张图片',
      'media.validation.type': '只可以上载JPEG或PNG格式的图片',
      'media.validation.error.file_size': '请上传小于5MB的图片',
      'media.validation.error.file_attachment_size': '请上传小于2MB的文件',
      'media.validation.error': '对不起，上载出现问题，请重试。',

      'attachment.upload.description':
        '档案不大于 2MB\n格式可为：pdf, doc, docx, xls, xlsx, csv, jpg, jpeg, png, gif',

      'imagepicker.title': '上载图片',

      'promotions.section.discount_summary.set.on_item':
        '<b>指定商品</b> {{ discount_value }}',
      'promotions.section.discount_summary.set.on_order':
        '{{ criteria_on ? "" : "<b>全店</b>" }} {{ discount_value }}',
      'promotions.section.discount_summary.discount_value':
        '{{ type == "amount" ? "即减" : "即享" }} <b>{{ type == "amount" ? currency : "" }}{{ value }}{{ type == "percentage" ? "折":"" }}</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products':
        '<b>指定商品</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal':
        '<b>全单</b>',
      'promotions.section.discount_summary.criteria.scope_discounted_products.content':
        '购买 <b>指定商品</b> <b>满{{ condition_value }}</b>，',
      'promotions.section.discount_summary.criteria.scope_discounted_subtotal.content':
        '<b>全单</b> 购买 <b>满{{ condition_value }}</b>，',
      'promotions.section.discount_summary.criteria.type_quantity':
        '{{ count }}件',
      'promotions.section.discount_summary.criteria.bundle_group':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 组</span> 任选 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 组</span> 任选 {{ green_count }} 件 共 {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_amount':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任选 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任选 {{ green_count }} 件 即減 {{ total }}',
      'promotions.section.discount_summary.criteria.bundle_group_percentage':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任选 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任选 {{ green_count }} 件 即享 {{ total }} 折',
      'promotions.section.discount_summary.criteria.bundle_group_gift':
        '指定商品：<span class="bundle-group-label bundle-group-red">A 組</span> 任选 {{ red_count }} 件，<span class="bundle-group-label bundle-group-green">B 組</span> 任选 {{ green_count }} 件 即享 {{ total }} 件赠品 ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_pricing':
        '指定商品：任选 {{ itemCount }} 件 共 {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_percentage':
        '指定商品：任选 {{ itemCount }} 件 即享 {{ discountValue }} 折',
      'promotions.section.discount_summary.criteria.bundle_amount':
        '指定商品：任选 {{ itemCount }} 件 即減 {{ discountValue }}',
      'promotions.section.discount_summary.criteria.bundle_gift':
        '指定商品：任选 {{ itemCount }} 件 即享 {{ discountValue }} 件赠品 ({{ giftName }})',
      'promotions.section.discount_summary.criteria.bundle_group.is_accumulated':
        '，买越多省越多！',
      'promotions.section.discount_summary.criteria.bundle_group.is_not_accumulated':
        '，最多优惠 1 组。',
      'promotions.section.discount_summary.content':
        '{{ criteria }}{{ benefits }}',
      'promotions.section.discount_percentage.caption': '{{ value }}折',

      'promotions.page.term.title': '条款与细则',
      'promotions.page.term.confirm': '确认',
      'promotions.page.term.default':
        '＊商品活动优惠以进入购物车结帐计算为准\n＊结帐时请选择活动指定付款与送货方式以取得优惠\n＊若活动仅适用会员，结帐时请登入以取得优惠',
      'promotions.page.start_at':
        '优惠活动开始日期 <span class="local-datetime">{{date}}</span>',
      'promotions.page.end_at':
        '优惠至 <b class="local-datetime">{{date}}</b> 截止',
      'promotions.page.redeem_gift.start_at':
        '活动开始日期 <span class="local-datetime">{{date}}</span>',
      'promotions.page.redeem_gift.end_at':
        '活动至 <b class="local-datetime">{{date}}</b> 截止',
      'promotions.page.display_local_timezone':
        '依据你的当地时区显示<br/>(GMT{{offset}})',

      'promotions.exclude.items.hint.title': '已是最优惠价格，不參與:',
      'promotions.exclude.items.hint.order_discount': '全店折扣优惠',
      'promotions.exclude.items.hint.member_tier': '会员默认优惠',
      'promotions.exclude.items.hint.user_credit': '购物金折抵',
      'promotions.exclude.items.hint.member_point': '点数折现',

      'product.page.title.product-info': '商品',
      'product.page.quantity': '数量',
      'product.page.unit_price': '单件价格',
      'product.page.subtotal': '小计',
      'product.page.add_to_cart_count':
        '已加入 <span class="order-detail-count">{{count}}</span> 项商品',
      'product.member_only': '会员独享',
      'product.tier_member_only': '{{tierName}} 独享',

      'products.quick_cart.quantity': '数量',
      'products.quick_cart.preorder_now': '现在预购',
      'products.quick_cart.add_to_cart': '加入购物车',
      'products.quick_cart.quantity_invalid':
        '该数量不适用，请填入有效的数量。',
      'products.quick_cart.out_of_stock': '售完',
      'products.quick_cart.out_of_stock_hint': '商品存货不足，未能加入购物车',
      'products.quick_cart.out_of_number_hint': '商品数量不足',
      'products.quick_cart.low_stock_hint': '您所填写的商品数量超过库存',
      'products.quick_cart.insufficient_point': '会员点数不足，请移除部分赠品',
      'products.quick_cart.reached_max_purchase_quantity':
        '商品购买上限为 {{ message }} 件',
      'products.quick_cart.quantity_of_stock_hint':
        '现库存只剩下 {{message}} 件',
      'products.quick_cart.messagetobuy': '若想购买，请联络我们。',
      'products.quick_cart.message': '联络我们',
      'products.quick_cart.show_more': '商品资讯',
      'products.message.title': '确定要移除这个商品吗？',
      'products.message.button_cancel': '取消',
      'products.message.button_ok': '确定',
      'products.purchase_limit_hint': '每位顾客限购 {{limit}} 件',
      'purchase_limit_hint.purchasable':
        '每位顾客限购 {{limit}} 件，您只能再购买 {{purchasable_qty}} 件',
      'products.purchase_limit_hint_can_not_purchase':
        '每位顾客限购 {{limit}} 件，您已购买达上限',

      'products.category.advance_filter.title': '筛选',
      'products.category.advance_filter.clear': '清除',
      'products.category.advance_filter.clear_all': '清除全部',
      'products.category.advance_filter.apply': '套用筛选',
      'products.category.advance_filter.show_more': '看更多',
      'products.category.advance_filter.show_less': '收起内容',
      'products.category.advance_filter.type.color': '颜色',
      'products.category.advance_filter.type.size': '尺寸',
      'products.category.advance_filter.type.brand': '品牌',
      'products.category.advance_filter.type.material': '材质',
      'products.category.advance_filter.type.price': '价格 ({{ currency }})',
      'products.category.advance_filter.tag.price_range':
        '{{ min_price }} ~ {{ max_price }}',
      'products.category.advance_filter.tag.min_price': '≥ {{ min_price }}',
      'products.category.advance_filter.tag.max_price': '≤ {{ max_price }}',
      'products.category.advance_filter.placeholder.min_price': '最小金额',
      'products.category.advance_filter.placeholder.max_price': '最大金额',

      'products.variant_selector.hide': '收起',
      'products.variant_selector.view_other_variations':
        '看其他 {{count}} 个选项',

      'hk_sfplus.region': '地区',
      'hk_sfplus.area': '范围',
      'hk_sfplus.district': '区域',
      'hk_sfplus.address': '{{region}} {{district}} {{area}}',

      'zeek.station.address': '自取点地址',
      'zeek.station.name': '自取点名称',
      'zeek.station.provider': '自取服务商',
      'zeek.pickup.region': '自取地区',
      'zeek.pickup.area': '自取区域',

      'tcat.time_slot.title': '选择指定到货时段',
      'tcat.time_slot.01': '13:00 以前',
      'tcat.time_slot.02': '14:00 - 18:00',
      // 03 key is no longer used according to tcat spec
      'tcat.time_slot.04': '任何时段',

      'subscribe.success': '订阅成功',
      'subscribe.failure': '订阅失败，请联络店家',
      'subscribe.line.failure': '订阅失败，请重新登入 LINE',
      'subscribe.line.failure.already_used':
        '订阅失败，此 LINE 帐号已被其他会员注册使用，请用 LINE 登入该帐号再订阅',
      'subscribe.line.failure.different_id':
        '订阅失败，请用注册此会员的 LINE 帐号订阅',
      'subscribe.facebook.failure': '订阅失败，请重新登入 FB',
      'subscribe.failure.already_bound':
        '订阅失败，此社群帐号已绑定其他顾客，请联络店家解除绑定',
      'unsubscribe.success': '取消订阅成功',
      'unsubscribe.failure': '取消订阅失败，请再试一次',

      'return_order.fill_infomation': '填写退货资料',
      'return_order.confirmation': '退货单确认',
      'return_order.select_return_product': '选取退货商品',
      'return_order.order_number': '订单编号',
      'return_order.product_name': '商品名称',
      'return_order.quantity': '数量',
      'return_order.unit_price': '单件价格',
      'return_order.return_quantity': '退货数量',
      'return_order.prcie_amount': '金额',
      'return_order.return_reason': '退货原因',
      'return_order.return_reason.not_expected': '商品不如预期',
      'return_order.return_reason.broke_during_delivery':
        '商品在运送过程中有毁损',
      'return_order.return_reason.wrong_variation': '商品尺寸规格不符',
      'return_order.return_reason.wrong_item': '商品寄错',
      'return_order.return_reason.other': '其他原因',
      'return_order.return_reason.admin_return_order': '由店家成立退货单',
      'return_order.return_reason.return_remark': '退货原因描述',
      'return_order.return_reason.return_remark_placeholder':
        '改变心意，限 60 个字元',

      'return_order.return_delivery.info': '退货资料',
      'return_order.return_delivery.delivery_option': '退货物流',
      'return_order.return_delivery.status': '退货状态',
      'return_order.return_delivery.receiver_name': '退货人姓名',
      'return_order.return_delivery.receiver_phone': '退货人电话',
      'return_order.return_delivery.receiver_address': '退货人地址',
      'return_order.return_delivery.optional': '选填',
      'return_order.return_delivery.destination': '地点',

      'return_order.return_payment.info': '退款资料',
      'return_order.return_payment.payment_option': '退款方式',
      'return_order.return_payment.status': '退款状态',
      'return_order.return_payment.custom_return':
        '其他（请与商家联系退款事宜）',
      'return_order.return_payment.bank_transfer_return': '提供退款帐号',
      'return_order.return_payment.bank_account': '银行名称与帐号',
      'return_order.return_payment.bank_transfer_placeholder':
        '812 台新銀行 xxxxxxxxxxxx',
      'return_order.form.agree_return_policy':
        '我同意 <a href="{{ link }}" target="_blank" rel="noopener">退换货政策</a>',
      'return_order.form.validation': '你必须同意退换货政策',
      'return_order.form.submit': '提交退货申请',
      'return_order.back_to_order_detail': '返回订单资讯',

      'return_order.detail.order_submitted': '您的退货单已经成立！ ',
      'return_order.detail.confirmation_emailed':
        '退货确认信已经发送到您的电邮',
      'return_order.detail.return_order_no': '退货资讯编号',
      'return_order.detail.apply_date': '申请日期',
      'return_order.confirmation.refund_note':
        '实际退款金额，待商家计算运费或各式优惠条件后，将显示于您的<a href="{{ link }}">退货资讯页面</a>',
      'return_order.confirmation.refund_question':
        '如有任何疑问，欢迎<a id="js-contact-us">联络客服</a>',

      'return_order.inspect.status': '验货状态',
      'return_order.inspect.status.pass': '验货成功',
      'return_order.inspect.status.fail': '验货失败',
      'return_order.refund.record': '退款纪录',
      'return_order.refund.amount': '退款金额',
      'return_order.refund.date': '退款日期',
      'return_order.return.total': '退货合计',
      'return_order.coninue_shopping': '继续购物',

      'return_order.inspect.status.no_inspect': '不验货',
      'return_order.inspect.status.pending': '未驗貨',
      'return_order.delivery_status.return_collected': '已退回',
      'return_order.delivery_status.returned': '已退货',
      'return_order.delivery_status.returning': '退货中',
      'return_order.delivery_status.pending': '退货中',
      'return_order.delivery_status.arrived': '已到达',
      'return_order.delivery_status.no_return': '不退回',
      'return_order.payment_status.pending': '未退款',
      'return_order.payment_status.refunded': '已退款',
      'return_order.payment_record.pending': '待處理',
      'return_order.popup.failed.title': '退货申请失败！',
      'return_order.popup.failed.content':
        '此物流方式订单金额上限为 {{ amount }} 台币，请重新更换物流方式再次送出订单退货的申请。',
      'return_order.popup.failed.confirm': '确定',

      'return_order.status': '退货单状态',
      'return_order.status.pending': '退货审核中',
      'return_order.status.confirmed': '确认退货',
      'return_order.status.completed': '退货完成',
      'return_order.status.cancelled': '取消退货',

      'order_comment_and_message.general_error':
        '系统错误，请与店主联络或稍后再试',
      'order_comment_and_message.rate_limit_error':
        '已达今日传送讯息上限，请于明天再试一次',

      'member_referral.discount': '会员推荐优惠',
      'member_referral.rules': '活动规则说明',
      'member_referral.code.copy_and_share':
        '复制并分享连结给朋友，推荐对象和你将获得购物金。',
      'member_referral.code.remarks': '活动规则说明',
      'member_referral.generate.code': '产生推荐连结',
      'member_referral.generate.code.success': '产生成功',
      'member_referral.copy.link': '复制连结',
      'member_referral.copy.link.success': '复制成功',
      'member_referral.welcome_credit.popup.title':
        '恭喜，你获得 {{reward_value}} 购物金',
      'member_referral.welcome_credit.popup.content':
        '透过朋友分享，你获得了 {{reward_value}} 购物金，可在结帐时使用。成为会员的你也可以分享给新朋友，并获得推荐优惠喔。',
      'member_referral.welcome_credit.popup.continue': '知道并继续购物',

      'lock_inventory.tooltip':
        '保留至 {{expired_time}}\n({{start_time}}开始的直播:{{event_name}})\n根据你的当地时区显示（GMT{{timezone}}）',
      'facebook_banner.description':
        '您正在使用Facebook app内建浏览器开启，如果上传图片无法成功，请改用其他浏览器；<span>点此复制连结</span>。',
      'in_app_browser_popup.desc':
        '若您需登入会员与购物车结帐，建议开启预设浏览器享有更好的购物体验！',
      'fb_in_app_browser_popup.desc':
        '您正在使用Facebook app内建的浏览器，请改用其他浏览器才可顺利结帐；',
      'fb_in_app_browser_popup.copy_link': '点此复制连结。',

      'product_set.build': '选取组合商品',
      'product_set.subtitle.unqualified':
        '立即新增下方商品，再加 <span class="highlight-label">{{ quantity }}</span> 件即可加入购物车',
      'product_set.subtitle.qualified': '已享有优惠，立即加入购物车！',
      'product_set.special_offer': '组合商品优惠',
      'product_set.items_needed':
        '尚需 <span class="highlight-label">{{ quantity }}</span> 件',
      'product_set.add_to_set': '加入組合',
      'product_set.items_needed.diff':
        '已选 {{ currentQuantity }} 件商品，尚需 <span class="highlight-label">{{ stillNeedQuantity }}</span> 件即享有组合优惠！',
      'product_set.add_to_cart': '加入购物车',
      'product_set.items_selected':
        '已选 <span class="highlight-label">{{ quantity }}</span> 件',
      'product_set.items_needed.hint':
        '再加入 <span class="highlight-label">{{ quantity }}</span> 件即可完成',
      'product_set.quantity_label.unqualified': '尚需 {{ quantity }} 件',
      'product_set.quantity_label.qualified': '已选 {{ quantity }} 件',
      'product_set.reach_limit': '已达选购上限',
      'product_set.reach_limit.congrats':
        '已选 <span class="highlight-label">{{ quantity }}</span> 件商品，恭喜享有组合优惠！',
      'product_set.no_items_selected': '尚无商品，请至上方选取加入组合',
      'product_set.quick_cart.add_to_set': '加入组合',
      'product_set.exceed_limit': '您所填写的商品数量超过组合商品选购上限',
      'product_set.set_includes': '组合商品内容：',
      'product_set.quantity': '组合数量',
      'product_set.sold_out': '库存不足',
      'product_set.please_select': '请选择商品规格',
      'product_set.not_enough_stock.please_remove':
        '商品数量超过库存，请删除此商品',
      'product_set.not_enough_stock': '您所填写的商品数量超过库存',

      'store_stock.loading': '确认库存中',
      'store_stock.take_a_while': '这会需要一些时间...',
      'store_stock.current_availability': '目前门市供货状况',
      'store_stock.description':
        '商品销售以现场情况为准，仅为预订顾客保留库存。建议您在前来门店之前先完成下单并确认取货日期。',
      'store_stock.available': '可于线上购买，门市取货',
      'store_stock.sold_out': '售完',

      'cookie_consent_popup.claim.heading': '此网站使用 cookies',
      'cookie_consent_popup.claim.description':
        'Hi, 本網站使用必要 cookies 和追蹤型 cookies 以確保網站服務，後者將在獲得你的同意後才會執行追蹤。',
      'cookie_consent_popup.claim.read_more': '了解更多',
      'cookie_consent_popup.buttons.settings': '设定偏好',
      'cookie_consent_popup.buttons.accept': '同意并继续',
      'cookie_consent_popup.buttons.save_preferences': '储存偏好设定',
      'cookie_consent_popup.cookie_names.necessary': '绝对必要 cookies',
      'cookie_consent_popup.cookie_names.statistical': '统计 cookies',
      'cookie_consent_popup.cookie_names.marketing': '行销应用 cookies',
      'cookie_consent_popup.cookie_names.functionality': '功能 cookies',
      'cookie_consent_popup.cookie_descriptions.necessary':
        '网站运作需要这些 Cookies 且您不能将其关闭，如果没有这些 Cookies，则无法提供网站预期服务。',
      'cookie_consent_popup.cookie_descriptions.statistical':
        '这些 Cookies 专门用于改善网站的性能以提供更好的用户体验。例如，访问者最常打开哪个网页，以及某些网页的错误消息。这些 cookies 不保存可以识别用户的信息，收集的信息是汇总的、匿名的。如果您不允许使用这些 Cookies，我们将不知道您如何使用我们的网站。',
      'cookie_consent_popup.cookie_descriptions.marketing':
        '这些 Cookie 用于传递与您的兴趣相关的广告并与您进行商业信息沟通，其内容会与第三方行销应用分享。',
      'cookie_consent_popup.cookie_descriptions.functionality':
        '这些 Cookies 匿名保存您已输入的信息（例如用户名、语言选择和您的位置），以在您的每次造访提供适配的预设选项。如果您不允许使用这些 Cookie，则可能无法实现部分功能。',

      'multi_checkout.items.title': '购物车',
      'multi_checkout.promotions.title': '优惠',
      'multi_checkout.change.payment_type.modal.title': '您正更改付款类型',
      'multi_checkout.change.payment_type.modal.description':
        '更改付款类型将需要重新选择送货方式及重新填写送货资讯',
      'multi_checkout.change.payment_type.modal.confirm': '变更',
      'multi_checkout.change.payment_type.modal.cancel': '取消',
      'multi_checkout.destination_section.title': '填写目的地',
      'multi_checkout.destination_section.address_type.new': '新地址',
      'multi_checkout.destination_section.delivery_type.label':
        '已选择的送货方式',
      'multi_checkout.destination_section.pick_store.label': '搜寻门市',
      'multi_checkout.destination_section.pick_store.store_code':
        '已选择门市店号',
      'multi_checkout.destination_section.pick_store.store_name':
        '已选择门市名称',
      'multi_checkout.destination_section.pick_store.store_address': '门市地址',
      'multi_checkout.destination_section.dynamic_fee.hint':
        '运费将于填写送货地址后显示',
      'multi_checkout.delivery_section.title': '选择付款类型与送货方式',
      'multi_checkout.delivery_section.country.label': '国家/地区',
      'multi_checkout.delivery_section.payment_type.label': '付款類型',
      'multi_checkout.delivery_section.delivery_option.label': '送货方式',
      'multi_checkout.delivery_section.collapse.hint': '展开送货提示',
      'multi_checkout.delivery_section.ncod': '非货到付款',
      'multi_checkout.delivery_section.cod': '货到付款',
      'multi_checkout.delivery_section.empty_delivery_methods': '没有适用方式',
      'multi_checkout.summary_section.title': '订单资讯',
      'multi_checkout.next_step': '下一步',
      'multi_checkout.delivery_attribute': '订单',
      'multi_checkout.summary_section.delivery_type.label': '送货方式：',
      'multi_checkout.summary_section.check_payment_instructions':
        '查看付款指示',
      'multi_checkout.summary_section.expand_payment_instructions':
        '展开付款指示',
      'multi_checkout.summary_section.confirm': '确认',
      'multi_checkout.cart_tag_unnamed': '(未命名)',
      'multi_checkout.checkout.destination.title': '目的地',
      'multi_checkout.checkout.delivery_time.description': '到货时间说明：',

      'birthday_picker.year.placeholder': '年',
      'birthday_picker.month.placeholder': '月',
      'birthday_picker.date.placeholder': '日',

      'coupon_v2.discount_type.discount': '折扣券',
      'coupon_v2.discount_type.free_shipping': '免运券',
      'coupon_v2.discount_type.gift': '赠品券',
      'coupon_v2.period.start_at': '{{ date }} 开始',
      'coupon_v2.period.end_at': '截至 {{ date }}',
      'coupon_v2.copy_code': '复制折扣码',
      'coupon_v2.copy_code.success': '复制成功',
      'coupon_v2.claim': '立即领取',
      'coupon_v2.claim.success': '恭喜获得 {{ name }} 优惠券',
      'coupon_v2.claimed': '已领取',
      'coupon_v2.use': '立即使用',

      'user_reminder.member_referral.user_credit':
        '注册为会员可获得 {{ value }} 元购物金。 ',
      'user_reminder.member_referral.member_point':
        '注册为会员可获得 {{ value }} 点会员点数。 ',
      'user_reminder.member_referral.action': '立刻注册',
      'user_reminder.coupon.member': '您有尚未领取的优惠券，请前往领取！',
      'user_reminder.coupon.guest': '请登入领取优惠券！',
      'user_reminder.coupon.action': '立即领取',

      'coupon_input.placeholder': '输入优惠券代码',
      'coupon_input.claim': '领取',
      'coupon_input.claim_succeed': '{{ couponName }} 领取成功！',
      'coupon_input.claim_succeed.redirect':
        '{{ couponName }} 领取成功！点击前往会员中心查看',
      'coupon_input.claim_failed': '领取失败',
      'verification.email.trial_merchant.reach_limit.failure':
        '商店试营运中无法发送验证邮件，如有需求请联系商店，谢谢。',
      'gift.exceed.limit': '您选择的赠品数量已超过上限',
      'gift.sold_out': '您选择的赠品已赠完',
      'gift.variation.confirm': '确认规格',
    });
  },
]);
